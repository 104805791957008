@import "../../../components/QuantityButton/quantityButton.less";

.details-bouquet-card {
  .row-bouquet {
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: @one-column-width) {
      flex-wrap: nowrap;
    }

    .paragraph-container {
      width: 100%;
      .paragraph {
        text-align: justify;
        @media (min-width: @one-column-width) {
          margin-left: 40px;
          text-align: end;
        }
      }
    }
  }
}

.image-product-gallery-container {
  width: 100%;
  height: 55vh;
  @media (min-width: @one-column-width) {
    height: 400px;
  }
  .image-product-gallery {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}
.thumbnail-image-product-gallery {
  width: 100%;
  height: 60px;
  object-fit: cover;
  @media (min-width: @one-column-width) {
    height: 90px;
  }
}

@border: 0 solid #dbdbdb;
@border-radius: 3px;
@modifier--text-color: #888;

.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f3f3;

  &:focus {
    background: red;
  }
}

@import "~antd/dist/antd.less";
@import (css) "~react-image-gallery/styles/css/image-gallery.css";
@import "./user/pages/Product/product.less";
@import "./user/pages/MyAccount/myAccount.less";
@import "./user/pages/Checkout/checkout.less";
@import "./components/CartDrawer/cartDrawer.less";
@import "./user/pages/Shop/shop.less";
@import "./user/pages/Home/home.less";
@import "./admin/pages/Configurations/configurations.less";
@import "./admin/pages/Orders/adminOrders.less";

@one-column-width: 992px;

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

body {
  background-color: #f0f2f5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-icon {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: #f5f5f5;

  :hover {
    color: @primary-color;
  }
}

.navbar {
  position: absolute;
  width: 100%;
  display: flex;
  flex-wrap: nowrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: 20px 20px 15px 20px;
  z-index: 1000;
}

.navbar-sticky {
  position: sticky;
  width: 100%;
  display: flex;
  flex-wrap: nowrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: 20px 20px 15px 20px;
  background-color: #ffffff;
  z-index: 1000;
}

.navbar-left {
  display: flex;
  justify-content: space-between;
}
.navbar-right {
  display: flex;
  align-content: flex-end;
}

.button-light {
  border-radius: 0px;
  border-style: solid;
  border-color: #f5f5f5;
  padding: 2px 12px 2px 12px;
  color: #f5f5f5;
  text-decoration: none !important;
  font-size: 1em;
  font-weight: 600;
}

.button-light:hover {
  border-style: solid;
  border-color: #f5f5f5;
  color: #303030;
  background-color: #f5f5f5;
}

.button-dark {
  border-radius: 0px;
  border-style: solid;
  border-color: #303030;
  padding: 6px;
  color: #303030;
  text-decoration: none !important;
  font-size: 12px;
  font-weight: 700;
}

.button-dark:hover {
  border-style: solid;
  border-color: #303030;
  color: #f5f5f5;
  background-color: #303030;
}

.button-dark-inverse {
  border-radius: 0px;
  border-style: solid;
  border-color: #303030;
  padding: 6px;
  color: #f0f2f5;
  background-color: #303030;
  text-decoration: none !important;
  font-size: 12px;
  font-weight: 700;
}

.button-dark-inverse:hover {
  border-style: solid;
  border-color: #303030;
  color: #303030;
  background-color: #f0f2f5;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  .item {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
}

.sub-menu-first-level {
  font-size: medium;
  font-weight: 800;
  .sub-menu-second-level {
    font-size: small;
    font-weight: 700;
  }
}

.login-form {
  max-width: 500px;
}
.login-form-button {
  width: 100%;
}

.ant-carousel .slick-dots li button {
  background: #07b07a;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #07b07a;
}

.card-product-price-option {
  width: 50%;

  ::after {
    display: block;
    padding-bottom: 100%;
  }
}

.ant-image-preview-operations {
  display: none;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #07b07a;
}

body::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  //background: orange;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #07b07a; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  //border: 3px solid orange;  /* creates padding around scroll thumb */
}

.home-page-panel {
  .ant-select-selector {
    height: auto !important;
  }
}

@primary-color: #07b07a;
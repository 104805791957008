.checkout-class {
  width: 100%;
  display: flex;
  justify-content: center;
  .centered-row {
    max-width: @one-column-width;
    width: 100%;
    .step-titles-row {
      width: 100%;
      background-color: #ffffff;
      margin: 16px 0;
      padding: 16px;
    }
    .step-content-card {
      width: 100%;
    }
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    @media (max-width: 480px) {
      display: flex;
      flex-direction: row;

      .ant-steps-item-content {
        display: none;
      }
    }
  }
  .overview-product-card {
    margin-bottom: 16px;
  }

  .ant-list-empty-text {
    display: none;
  }
}

.full-space-between-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
}

.centered-align-items {
  display: flex;
  align-items: center;
}

.mb-12 {
  margin-bottom: 12px;
}

.centered {
  justify-content: center;
  align-items: center;
}

.full-space-row {
  width: 100%;
  display: flex;
}

.card-fixed-shadow {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.opening-hours-panel {
  .ant-form-item-control-input-content {
    flex: unset;
  }

  .input-day-name {
    text-transform: uppercase;
    font-weight: bold;
    border: unset;
    background-color: #ffffff;
    color: #000000;
  }
}

.home-page-carousel-form {
  .ant-select-selector {
    height: auto !important;
  }
}

.images-handler-panel {
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }

  .upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
    float: right;
  }
}

@one-column-width: 992px;
@dt-top-height: calc(100vh / 3 * 1.75 * 1);
@dt-bottom-height: calc(~"(100vh / 3 * 1.25) - 16px");

.home {
  .top-height {
    height: @dt-top-height;
    @media (max-width: @one-column-width) {
      height: 500px;
    }
    .carousel-image {
      display: block;
      width: 100%;
      position: absolute;
      object-fit: cover;
      opacity: 0.95;
      height: @dt-top-height;
      @media (max-width: @one-column-width) {
        height: 500px;
      }
    }
    .carousel-video {
      width: 100%;
      position: absolute;
      height: @dt-top-height;
      @media (max-width: @one-column-width) {
        height: 500px;
      }
      video {
        display: block;
        object-fit: cover;
      }
    }
    .carousel-video-overlay {
      width: 100%;
      position: absolute;
      height: @dt-top-height;
      @media (max-width: @one-column-width) {
        height: 500px;
      }
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHUlEQVQYV2P88OGDsYCAwFkGKGCEMTAEYCoxVAAAXRUIBfzFPSEAAAAASUVORK5CYII=)
        repeat;
    }
  }
  .divider-row {
    margin-top: 16px;
    @media (max-width: @one-column-width) {
      margin-top: 0;
    }
  }
  .bottom-height {
    height: @dt-bottom-height;
    @media (max-width: @one-column-width) {
      height: 500px;
    }
    .card-image {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      filter: brightness(95%);
      height: @dt-bottom-height;
      @media (max-width: @one-column-width) {
        height: 500px;
      }
    }
  }
  .home-card-left {
    padding-right: 8px;
    @media (max-width: @one-column-width) {
      padding-right: 0px;
    }
  }

  .home-card-right {
    padding-left: 8px;
    @media (max-width: @one-column-width) {
      padding-left: 0px;
    }
    .home-card {
      margin-left: 8px;
      @media (max-width: @one-column-width) {
        margin-left: 0px;
      }
    }
  }
}

.main-content {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  .main-content-row {
    width: 1300px;
    max-width: 100%;
  }
  .main-content-card {
    margin-top: 30px;
  }

  .ant-pagination-item{
    display: none;
  }

  .ant-descriptions-item-content {
    text-align: left;
  }
}

.my-info-modal {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

.user-orders-table {
  .ant-table {
    font-size: 0.8em;
  }
}

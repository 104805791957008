.quantity-input__modifier,
.quantity-input__screen {
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.3rem;
  width: 1.5rem;
  font-size: 1.2rem;

  background: #f3f3f3;
  color: @modifier--text-color;
  border: @border;
  text-align: center;

  cursor: pointer;

  &:hover {
    background: darken(#f3f3f3, 10%);
    color: darken(@modifier--text-color, 20%);
  }
}

.quantity-input__screen {
  width: 2.2rem;
  padding: 0.3rem;
  font-size: 1rem;

  border: 0;
  border-top: @border;
  border-bottom: @border;
  text-align: center;
}
